<template>
  <div>
    <v-sheet
      min-height="70vh"
      rounded="lg"
    >
    <!-- <model-gltf src="img/3d/s-plomb.gltf"></model-gltf> -->
      <!-- <model-obj src="img/3d/Motorcycle_engine.obj"></model-obj> -->
      <!-- <model-gltf src="3d/plomb.glb"></model-gltf> -->
          <model-viewer
            style="height:90vh;width:100%"
            src="img/3d/irvis-2.gltf" alt="A 3D model of an astronaut"
            camera-orbit="200deg 70deg 10m"
            camera-target="0m 0m 0m"
          camera-controls></model-viewer>
          <div id="lazy-load-poster" slot="poster"></div>
          <div id="button-load" slot="poster">Load 3D Model</div>
          <!-- camera-orbit="20deg 70deg 35m"
            camera-target="0m 3m 0m" -->

    </v-sheet>
  </div>
</template>
<script>
export default {

}
</script>
<style scoped>
  #lazy-load-poster {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    /* background-image: url("../img/3d/pipe.png"); */
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  #button-load {
    /* background-image: url("../img/3d/download.svg"); */
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position: 6% 50%;
    background-color: #000;
    color: white;
    cursor: pointer;
    border-radius: 6px;
    display: inline-block;
    padding: 10px 18px 9px 40px;
    font-weight: 500;
    box-shadow: 0 0 8px rgba(0,0,0,.2), 0 0 4px rgba(0,0,0,.25);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: 100;
  }
</style>
